<template>
  <div class="register">
    <starrySky />
    <Header>
      <img class="logo" src="@/assets/logo_white.png" alt="logo">
      <span class="title font-16 color-fff">大数据监控后台</span>
    </Header>
    <div class="reg_bd">
      <div class="reg_step">
        <div class="pt-30-pb-20 flex-between">
          <p class="font-20 color-fff">找回密码</p>
          <router-link to="/login" class="to-back font-14 color-fff">返回登录页</router-link>
        </div>
        <div class="reg_step_bd font-16" :class="'step' + step">
          <span class="flex-1" :class="step === 1 ? 'color-fff' : 'color-979797'">1.填写手机验证码</span>
          <span class="flex-1" :class="step === 2 ? 'color-fff' : 'color-979797'">2.设置新密码</span>
          <span class="flex-1" :class="step === 3 ? 'color-fff' : 'color-979797'">3.完成</span>
        </div>
      </div>
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import starrySky from '@/components/starrySky/index'

export default {
  name: 'register',
  components: {
    Header,
    starrySky
  },
  watch: {
    '$route' (to) {
      this.step = to.name.substring(4) * 1
      let url = ['/register/step2', '/register/step3']
      if (url.indexOf(to.path) !== -1 && JSON.stringify(to.params) === '{}') {
        this.$router.push({ path: '/register/step1' })
      }
    }
  },
  data () {
    return {
      step: 1
    }
  }
}
</script>

<style lang="less">
.register {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  .color-979797 {
    color: #979797;
  }
  .pt-30-pb-20 {
    padding: 30px 0 20px;
  }
  .logo {
    display: inline-block;
    width: 7.08vw;
    height: auto;
  }
  .title {
    position: relative;
    margin-left: 1.25vw;
    &::before {
      content: '';
      position: absolute;
      left: -0.56vw;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 1.8vh;
      background-color: #fff;
    }
  }
  .reg_bd {
    position: relative;
    margin: 120px auto;
    width: 680px;
    background: linear-gradient(180deg, #12183D 0%, #121645 100%);
    border: 2px solid #2EDBFF;
    border-radius: 6px;
    box-sizing: border-box;
    // border-radius: 6px;
    // box-sizing: border-box;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: -2px;
    //   left: -2px;
    //   width: calc(100% + 4px);
    //   height: calc(100% + 4px);
    //   background: linear-gradient(60deg, #5f86f2, #a65ff2, #ccddff, #2483FF, #01D18A, #2EDBFF);
    //   background-size: 300% 300%;
    //   background-position: 0 50%;
    //   border-radius: 6px;
    //   animation: moveGradient 4s alternate infinite;
    //   z-index: -9;
    // }
    .reg_step {
      padding: 0 30px;
      p {
        margin: 0;
      }
      .to-back {
        text-decoration: none;
      }
      .reg_step_bd {
        display: flex;
        align-items: center;
        position: relative;
        padding-bottom: 20px;
        height: 36px;
        background-position: top;
        background-repeat: no-repeat;
        background-size: contain;
        &::after {
          position: absolute;
          content: '';
          left: -30px;
          right: -30px;
          bottom: 0;
          height: 1px;
          background-color: #BFBFBF;
        }
        .flex-1 {
          flex: 1;
          text-align: center;
        }
      }
      .step1 {
        background-image: url('../../assets/step_1.png');
      }
      .step2 {
        background-image: url('../../assets/step_2.png');
      }
      .step3 {
        background-image: url('../../assets/step_3.png');
      }
    }
  }
  @keyframes moveGradient {
    50% {
      background-position: 100% 50%;
    }
  }
}
</style>
