<template>
  <header class="head">
    <div class="head-box">
      <slot></slot>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped lang="less">
.head {
  position: relative;
  height: 65px;
  margin-bottom: 1.5rem;
  z-index: 99;
  &::before, &::after {
    content: '';
    position: absolute;
    width: 25%;
    height: 1px;
    bottom: 0;
    background-color: #2483FF;
    z-index: -1;
    animation: 2s flash infinite;
    box-shadow: 0 1px 30px #0093df, 0 1px 20px #0093df inset;
  }
  &::before {
    left: 0;
    transform-origin: left center;
  }
  &::after {
    right: 0;
    transform-origin: right center;
  }
  .head-box {
    display: flex;
    align-items: center;
    height: 100%;
    &::before, &::after {
      content: '';
      position: absolute;
      width: 5%;
      height: 1px;
      bottom: -6px;
      background-color: #2483FF;
      z-index: -1;
      animation: 2s flash infinite;
      box-shadow: 0 1px 30px #0093df, 0 1px 20px #0093df inset;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
}
@keyframes flash {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
